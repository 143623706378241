<template>
  <div class="m_header">
    <div class="idx-header logbefore" :class="$auth.$state.loggedIn? 'logafter':'logbefore'"><!-- 登入前:logbefore,  登入後:logafter-->
      <nuxt-link to="/mobile" class="btn-logo"></nuxt-link>

      <!-- <modLogin v-for="(item, index) in LoginBtn" v-model="LoginBtn[index]" :key="index" v-if="item.show" /> 登入前-->

      <template v-if="DEF_webStyle === 'fl8n' || DEF_webStyle === 'fl88' || DEF_webStyle === 'jh8'">
        <audioPlay />
      </template>
      <template v-if="$auth.$state.loggedIn === false"><!-- 登入前 -->
        <template v-if="DEF_webStyle === 'sgph'">
          <b-button class="license" @click="showImage = true" >
            Show License
          </b-button>
          <div class="licensebox" v-if="showImage">
            <div class="license-img">
              <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
              <button @click="closeImage" class="license-X" >X</button>
            </div>
          </div>
        </template>
        <nuxt-link to="/mobile/account/login" class="login-before btn-login">
          {{ $t("store.btn.login") }}
        </nuxt-link>
        <nuxt-link to="/mobile/account/signup" class="login-before btn-signup">
          {{ $t("store.btn.signup") }}
        </nuxt-link>
        <nuxt-link to="/mobile/account/login" class="login-before btn-sign">
          {{ $t("store.btn.header_login_signup") }}
        </nuxt-link>
      </template>

      <template v-if="$auth.$state.loggedIn === true"><!-- 登入後 -->
        <idxLogafterUserInfo v-if="$auth.$state.loggedIn === true"></idxLogafterUserInfo>
        <b-button class="btn-logout" block @click="OnLogout">{{ $t("store.btn.logout") }}</b-button>
        <template v-if="DEF_webStyle === 'sgph'">
          <b-button class="license" @click="showImage = true" >
            Show License
          </b-button>
          <div class="licensebox" v-if="showImage">
            <div class="license-img">
              <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
              <button @click="closeImage" class="license-X" >X</button>
            </div>
          </div>
        </template>
        <dialogExchPoint />
        <dialogTransferPoint />
      </template>
      <vuei18n />
    </div>
  </div>
</template>

<script>
const audioPlay = require(`~/components/${process.env.DEF_audioplay}.vue`).default;
const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
import idxLogafterUserInfo from "~/components/mobile/idxLogafterUserInfo.vue";
import dialogExchPoint from "@/components/mobile/dialogExchPoint.vue";
import dialogTransferPoint from "@/components/desktop/dialogTransferPoint.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import allBackWalletBtn from "~/components/desktop/allBackWalletBtn.vue";
export default {
  components: {
    idxLogafterUserInfo,
    dialogExchPoint,
    dialogTransferPoint,
    vuei18n,
    audioPlay,
    allBackWalletBtn
  },
  data() {
    return {
      form: {
        pw: "",
        name: "",
        food: "",
        checked: [],
      },
      show: true,
      Refresh: null,
      AccountWalletMain: 0,
      AccountWalletMainLoading: false,
      showImage: false,
    };
  },
  created() {
    this.SetAutoRefresh();
    if (this.$auth.$state.loggedIn === true)
      this.getNureadNotice();

    if (Object.keys(this.AccountWallet).indexOf("0") >= 0)
      this.OnAccountWalletChanged();
    if (Object.keys(this.AccountWalletLoading).indexOf("0") >= 0)
      this.OnAccountWalletLoadingChanged();

    this.$watch(() => this.AccountWallet[0], this.OnAccountWalletChanged);
    this.$watch(
      () => this.AccountWalletLoading[0],
      this.OnAccountWalletLoadingChanged
    );
  },
  beforeDestroy() {
    clearInterval(this.Refresh);
  },
  methods: {
    closeImage() {
      this.showImage = false;
    },
    audioClick() {
      if (['jdf', 'fl8n', 'fl88', 'jh8'].indexOf(this.DEF_webStyle) < 0)
        return '';

      if (!this.IsAudio)
        return '';

      try {
        function isIOS() {
          return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        };

        const audio = document.querySelector("#beep");
        const context = new AudioContext();
        if (context.state === 'suspended') {
          context.resume().then(() => {});
        };
        if (isIOS()) {
          setTimeout(function() {
            audio.muted = false;
            audio.play();
          }, 200);
        } else {
          audio.muted = false;
          audio.play();
        };
      } catch (e) {
        console.log(e);
      }
    },
    OnLogout() {
      this.$auth.logout("local");
    },
    RefreshAccountWallet() {
      this.GetAccountWallet(0);
    },
    SetAutoRefresh() {
      if (this.Refresh)
        clearInterval(this.Refresh);

      this.Refresh = setInterval(() => {
        if (this.$auth.$state.loggedIn === true) this.getNureadNotice();
      }, 60000);
    },
    OnAccountWalletChanged() {
      this.AccountWalletMain = this.AccountWallet[0];
    },
    OnAccountWalletLoadingChanged() {
      this.AccountWalletMainLoading = this.AccountWalletLoading[0];
    },
    init() {
      if (this.ErrorMsg != "") {
        this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
          this._setErrorMsg("");
        });
      }
    },
    ...mapActions("account", ["GetAccountWallet"]),
    ...mapActions("webSetting", ["getNureadNotice"]),
    ...mapActions(["_showMsgBox"]),
    ...mapMutations(["_setErrorMsg"]),
  },
  mounted() {
    this.init();
  },
  watch: {
    "$auth.$state.loggedIn"(loggedIn) {
    },
    "$auth.$state.user"(user) {
    },
    'ErrorMsg' (ErrorMsg) {
      if (ErrorMsg && ErrorMsg != '') {
        this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
          this._setErrorMsg("");
        });
      }
    }
  },
  computed: {
    UserName() {
      if (this.WebSetting.MasterAccount === "account")
        return this.$auth.$state.user.account;
      else
        return this.$auth.$state.user.mobile || this.$auth.$state.user.account;
    },
    M_header_btn() {
      return process.env.M_header_btn;
    },
    M_header_ham() {
      return process.env.M_header_ham;
    },
    M_header_user() {
      return process.env.M_header_user;
    },
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(["WebSetting", 'IsAudio']),
    ...mapGetters(["Meun"]),
    ...mapState("account", ["AccountWallet", "AccountWalletLoading"]),
    ...mapState(['ErrorMsg', 'GoToJoin']),
  },
};
</script>
