<template>
  <b-modal id="bv-modal-example" ref="myModalRef" :modal-class="MyClass" hide-footer hide-header>
      <div class="hot_menu">
        <modAutoTransfer  v-show="$auth.$state.loggedIn === true"/>
        <modHideLink v-for="(item, index) in Original" v-model="Original[index]" :key="index" @click.native="hideModal" v-if="item.show"></modHideLink>
        <b-button variant="close" @click="hideModal">
            <i class="icon icon-close"></i>
            <span>{{ this.$t("store.btn.close") }}</span>
        </b-button>
      </div>
      <modHideMsg />
      <modHideMain type="tab"  v-if="$auth.$state.loggedIn === true" />
      <modHideMain type="help" />
      <b-button class="mbtnin-logout" block @click="OnLogout" v-if="$auth.$state.loggedIn === true">
          <i class="icon icon-logout"></i>
          <span aria-hidden="true">{{ this.$t("store.btn.logout") }}</span>
        </b-button>
  </b-modal>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import modAutoTransfer from "~/components/mobile/header/modAutoTransfer.vue";
import modHideMsg from "~/components/mobile/header/modHideMsg.vue";
import modHideMain from "~/components/mobile/header/modHideMain.vue";
import modHideLink from "~/components/mobile/header/modHideLink.vue";
  export default {
    components:{
      modAutoTransfer,
      modHideMsg,
      modHideLink,
      modHideMain
    },
    data(){
      return{
      }
    },
    methods: {
      hideModal() {
        this.$root.$emit('bv::hide::modal', 'bv-modal-example', '#myModalRef');
      },
      OnLogout() {
        this.$auth.logout("local");
        this.$root.$emit('bv::hide::modal', 'bv-modal-example', '#myModalRef');
      },
    },
    computed: {
      Original() {
        return [
          {
            to: "/mobile/system/mail",
            class:"mbtnin-mail",
            icon: "icon-mail",
            txt: this.$t("store.web_mail"),
            show: this.$auth.$state.loggedIn
          },
          {
            to: "/mobile/wallet/bank",
            class:"mbtnin-bank",
            icon: "icon-wallet",
            txt: this.$t("store.wallets"),
            show: this.$auth.$state.loggedIn
          },
          {
            to: "/mobile/wallet/discount",
            class:"mbtnin-disc",
            icon: "icon-gift",
            txt: this.$t("store.discount.apply"),
            show: this.ShowDiscount && this.$auth.$state.loggedIn
          }
        ]
      },
      ShowDiscount() {
        return this.WebSetting.SHOW_DISCOUNT === "1";
      },
      MyClass(){
        return {
          'mbtnin-wrap': true,
          ...this.FestivalClass
        }
      },
      ...mapState(['WebSetting']),
      ...mapGetters('webSetting', ['FestivalClass'])
    }
  }
</script>
