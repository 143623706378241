<template>
  <div class="float_btn" v-if="CloseAll.indexOf(value.id) < 0">
    <span class="fab_close" @click.stop="close_x(value.id)"></span>

    <template v-if="value.type == 'a'">
      <a :id="value.id" :href="value.url" :class="value.btnStyle" target="_blank">
        <div class="fab_default" v-if="value.imgOff">
          <i :class="value.defaultIcon"></i>
          <span>{{ $t(value.defaultName) }}</span>
        </div>
        <div class="fab_img" v-else>
          <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/icon/${value.imgSrc}.webp`)" :alt="value.imgCaption">
        </div>
      </a>
    </template>

    <template v-if="value.type == 'b'">
      <b-button :id="value.id" :to="value.to" :class="value.btnStyle" @click="btnFlextoggleModal( value.needLogin, value.click[0], value.click[1])">
        <div class="fab_default" v-if="value.imgOff">
          <i :class="value.defaultIcon"></i>
          <span>{{ $t(value.defaultName) }}</span>
        </div>
        <div class="fab_img" v-else>
          <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/mobile/${value.imgSrc}.webp`)" :alt="value.imgCaption">
        </div>
      </b-button>
    </template>

    <template v-if="value.type == 'c'">
      <a :href="WebSetting.LINELINK" :class="value.btnStyle" target="_blank">
        <div class="fab_default" v-if="value.imgOff">
          <i :class="value.iconStyle"></i>
          <span>{{ $t(value.name) }}</span>
          <span class="lind_id" v-if="value.show">{{ WebSetting.LINEID }}</span>
        </div>
        <div class="fab_img" v-else>
          <img class="img-fluid" :src="require(`@/website/${DEF_webStyle}/images/mobile/${value.imgSrc}.webp`)" :alt="value.imgCaption">
        </div>
      </a>
    </template>


  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "m_FAB",
  props: {
    value: Object,
  },
  data() {
    return {
    };
  },
  methods: {
    close_x(id) {
      this._setCloseAll(id);
      //this.$emit('xx_close', id);
    },
    btnFlextoggleModal(status, modal, btnTag) {
      if (modal == "off")
        return ;
      if (status && this.$auth.$state.loggedIn === false)
        this.$router.push({ name: 'account-login', params: null, query: null });
      else
        this.$root.$emit("bv::toggle::modal", modal, btnTag);
    },
    ...mapMutations(['_setCloseAll']),
  },
  computed: {
    DEF_webStyle() {
      return process.env.DEF_webStyle || 'default';
    },
    ...mapState(["WebSetting",'CloseAll']),
  }
}
</script>
