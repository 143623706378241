<template>
  <div class="hotgameBox">
    <div class="hot_s1box" :class="{active: checked}">

      <div class="gameiconbox">
        <template v-for="(item, index) in MeunTitle">
            <div v-show="item.id != 0 || HotGame.length > 0" class="game_leftbtn" :class="categoryId==item.id ? 'on' : ''" @click.prevent="scrolltest(index)">
              <i :class="item.class"></i>
              <span>{{ $t(item.title) }}</span>
            </div>
        </template>
      </div>

      <div class="hot-pro idxHotpro5" id="gamebigbox" ref="boxdiv">
        <template  v-for="(link, index) in MeunContent">

          <template v-if="link.categoryId === 0">
            <div class="gamelibox hot">
              <template v-for="obj in link.content">
                <a
                  v-if="obj"
                  :id="`c${link.categoryId}`"
                  :class="`game-list product_${obj.product_id} ${obj.maintain}`"
                  :data-dsss="obj.categoryId"
                  :data-text="$t('store.uphold_ing')"
                  @click="OpenGame(link.categoryId, obj, false)"
                >
                  <template v-if="[3, 6, 7, 8].indexOf(obj.category_id) >= 0">
                    <img :src="obj.imgPath" :alt="obj.name"/>
                    <a v-if="list.hasTryGame" class="btn btn-try" @click="tryGame(categoryId, obj.id)">{{ $t("store.btn.try_play") }}</a>
                  </template>
                  <template v-else>
                    <img class="hot_index_bg" :src="`/images/game/hot/product_${obj.product_id}.webp`" :alt="obj.name"/>
                  </template>
                  <span>{{ obj.name }}</span>
                </a>
              </template>
            </div>
          </template>

          <template v-if="[3, 7, 8].indexOf(link.categoryId) >= 0">
            <div class="gamelibox">
              <a
                @click="ToEgame(link.id)"
                :class="`game-list product_${link.id} ${link.maintain}`"
                :data-text="$t('store.uphold_ing')"
                :data-dsss="link.categoryId"
                :id="`c`+link.categoryId"
              >
                <span>{{ link.gameName }}</span>
              </a>
            </div>
          </template>

          <template v-else-if="[6].indexOf(link.categoryId) >= 0">
            <div class="gamelibox">
              <a
                @click="ToEgame('fish')"
                :class="`game-list product_fish ${link.Maintain}`"
                :data-text="$t('store.uphold_ing')"
                :data-dsss="link.categoryId"
                :id="`c`+link.categoryId"
              >
                <span>{{ $t(link.gameName) }}</span>
              </a>
            </div>
          </template>

          <!-- <template v-else-if="[100].indexOf(link.categoryId) >= 0">
              <div class="gamelibox">
                <a v-if="link.blank" target="_balnk" :href="link.url" :class="link.class" :data-dsss="link.categoryId" :id="`c`+link.categoryId">{{ link.gameName }}</a>
                <nuxt-link v-else :to="`/mobile${link.url}`" :class="link.class" :data-dsss="link.categoryId" :id="`c`+link.categoryId">{{ link.gameName }}</nuxt-link>
              </div>
          </template> -->

          <template v-else-if="link.categoryId !== 0">
            <div class="gamelibox" :class="`c`+link.categoryId">
              <a
                @click="playGame(link.categoryId, link.id, link.maintain)"
                :class="`game-list product_${link.id} ${link.maintain}`"
                :data-text="$t('store.uphold_ing')"
                :id="`c`+(link.categoryId)"
              >
                <span>{{ link.gameName }}</span>
              </a>
            </div>
          </template>

        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
  import { getJsonFile } from '~/utils/jsonFile';
  export default {
    data() {
      return {
        //idxInfo: null,
        checked: false,
        title: this.$t("store.hotpro_title"),
        list: [],
        categoryId: 1,
        midBoxId: 'gamebigbox',
        MeunTitle: [],
        MeunContent: [],
        hotMenu: [
          [1, 2, 53],
          [29, 45, 10]
        ],
        firstMenu:[
          {
            id: 0,
            class: 'icon icon-hotpro',
            title: 'store.s_hot'
          }
        ],
      };
    },
    created(){
      this.checked = this.IsLeft;

      // if (Object.keys(this.$route.query).indexOf('x') >= 0)
      //   this.idxInfo = getJsonFile(process.env.DEF_webStyle, 'idxInfo_x');
      // if (this.idxInfo == null || this.idxInfo.length == 0)
      //   this.idxInfo = getJsonFile(process.env.DEF_webStyle, 'idxInfo');

      this.changeLang();
    },
    methods: {
      // findJackpot(){
      //   const data = this.MeunContent;
      //   const index = data.findIndex(obj => obj.categoryId === 3);
      //   data.splice(index, 0 , {categoryId: 3, gameName: '封神榜', id: 'jackpot'});
      // },
      showModal: function () {
        this.$refs.myModalRef.show();
      },
      hideModal() {
        this.$refs.myModalRef.hide();
      },
      Login() {
        this.$router.replace({ path: "/mobile/account/login" });
      },
      tryGame(ProductId, gameId, Maintain) {
        if (Maintain != "") return;

        this.getTryGame(gameId).then((res) => {
          if (res.url) window.open(res.url);
          else console.log(res);
        });
      },
      playGame(ProductId, GameId, Maintain) {
        if (Maintain != "")
          return;

        if (this.$auth.$state.loggedIn === false) {
          this.Login();
          return;
        }

        let GameData = this.Product(ProductId).find(element => element.Id === GameId);
        if (!this.ShowTransferModal && GameData.WalletCode != 'obchess') {
          window.open(GameData.Url);
          return;
        }

        this.setPlayGameData(GameData);
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
      },
      ToUrl(Url, ProductId) {
        let GoReplace = { path: Url, query: { ProductId }};
        this.$router.replace(GoReplace);
      },
      ToEgame(ProductId) {
        let GoReplace = { path: "/mobile/game/egame", query: { ProductId }};
        // if (ProductId == 'jackpot')
        //   GoReplace.path = "/mobile/game/jackpot";
        if ([1,2].indexOf(ProductId) >= 0)
          GoReplace.path = "/mobile/game/live";
        if ([71].indexOf(ProductId) >= 0)
          GoReplace.path = "/mobile/game/sport";
        if ([29].indexOf(ProductId) >= 0)
          GoReplace.path = "/mobile/game/lottery";
        if (ProductId == '14')
          GoReplace.path = "/mobile/game/multiple";
        if (ProductId == '15')
          GoReplace.path = "/mobile/game/battle";
        if (ProductId == 'fish')
          GoReplace.path = "/mobile/game/fish";

        this.$router.replace(GoReplace);
      },
      OpenGame (CategoryId, GameData, IsTry) {
        if ([3, 7, 8].indexOf(CategoryId) >= 0) {
          let GoReplace = { path: "/mobile/game/egame", query: { ProductId }};
          this.$router.replace(GoReplace);
          return;
        }

        if ([6].indexOf(CategoryId) >= 0) {
          let GoReplace = { path: "/mobile/game/fish"};
          this.$router.replace(GoReplace);
          return;
        }

        let Maintain = GameData.maintain;
        if (Maintain != "")
          return;

        if (this.$auth.$state.loggedIn === false) {
          this.Login();
          return;
        }

        if (IsTry) {
          window.open(GameData.tryGameUrl);
          return;
        }

        if (!this.ShowTransferModal) {
          window.open(GameData.playGameUrl);
          return;
        }

        this.setPlayGameData({ WalletCode: GameData.wallet_code, Url: GameData.playGameUrl });
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
        return;
      },
      scrolltest(index) {
        let boxdiv        = this.$refs.boxdiv;
        let categoryId_n  = this.MeunTitle[index].id;
        // let margin        = document.querySelectorAll("#c0")[0].parentElement.offsetTop;
        let liList        = document.querySelectorAll(`#c`+categoryId_n)[0];
        let abcccc        = null;

        // console.log(document.querySelectorAll("#c0"));
        // console.log(document.querySelectorAll("#gamebigbox")[0].parentElement.offsetTop)

        this.MeunContent.forEach(function(item, index) {
          if (abcccc === null && categoryId_n == item.categoryId) {
            abcccc = index;
          }
        })

        let itemHeight = liList.parentElement.offsetTop // - margin;
        this.backtop(boxdiv, itemHeight);
      },
      backtop(tabScroll, max) {
        clearInterval(this.timeTop);
        if (tabScroll.scrollTop == max)
          return;

        let nowScrollTop = 0;
        tabScroll.scrollTop = max;
        // this.timeTop = setInterval(() => {
        //   if (
        //     (tabScroll.scrollTop > max && tabScroll.scrollTop - 10 < max) ||
        //     (tabScroll.scrollTop < max && tabScroll.scrollTop + 10 > max)
        //   )
        //     tabScroll.scrollTop = max;
        //   else if (tabScroll.scrollTop > max) tabScroll.scrollTop -= 10;
        //   else if (tabScroll.scrollTop < max) tabScroll.scrollTop += 10;
        //   else clearInterval(this.timeTop);
        //   if (nowScrollTop == tabScroll.scrollTop) clearInterval(this.timeTop);
        //   nowScrollTop = tabScroll.scrollTop;
        // }, 0.5);
      },
      hotSidegoTop() {
        let boxdiv = this.$refs.boxdiv;
        this.backtop(boxdiv, 0);
      },
      hotproSpin() {
        let DivId        = null;
        let DivSeat      = null;
        let DivTop       = document.getElementById(this.midBoxId).scrollTop;
        //let DivMargin    = document.querySelectorAll("#c0")[0].parentElement.offsetTop;
        let DivGameliBox = document.querySelectorAll(`#${this.midBoxId} .gamelibox`);

        if (DivGameliBox.length == 0)
          return;

        DivGameliBox.forEach(function(Item, Index) {
          let DivHeight = Item.offsetTop + 10; // + (Item.offsetHeight / 2);
          if (DivSeat === null || Math.abs(DivTop - DivHeight) < DivSeat) {
            DivSeat = Math.abs(DivTop - DivHeight);
            DivId   = Index;
          }
        });

        if (DivTop == 0)
          this.setCategoryId(this.MeunContent[0]);
        else
          this.setCategoryId(this.MeunContent[DivId]);

        return;
      },
      setCategoryId(content) {
        this.categoryId = content.categoryId || 0;
      },
      changeLang() {
        this.title        = this.$t("store.hotpro_title");
        this.MeunTitle    = [...this.firstMenu, ...this.MenuData];
        this.MeunContent  = [...this.MenuHotData, ...this.MeunContentData];
      },
      ...mapActions(["_getJackpot"]),
      ...mapActions("game", ["getPlayGame", "getTryGame"]),
      ...mapMutations("game", ["setPlayGameData"]),
    },
    mounted() {
      if(this.DEF_webStyle === 'jdf'){
        this.findJackpot();
      }
      // this.getData();
      this.hotproSpin();

      document.querySelector(`#${this.midBoxId}`).addEventListener('scroll', this.hotproSpin, true);
      if (this.autoplay) this.autoPlay();
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
      },
      IsLeft(value) {
        if (value == this.IsLeft)
          return this.checked = !this.checked;

        this._setIsLeft(value);
      },
    },
    computed: {
      M_ExLink8899(){
        return process.env.M_ExLink8899;
      },
      M_ExLink539(){
        return process.env.M_ExLink539;
      },
      M_ExLink() {
        return process.env.M_ExLink;
      },
      MenuData() {
        return this.MobileMeun.map((item, index) => {
          return { id: item.id, class: item.class, title: item.title };
        });
      },
      MenuHotData() {
        let Tmp  = [];
        let Data = [];

        this.HotGame.forEach(function(Item) {
          Tmp.push(Item);

          if (Tmp.length >= 3) {
            Data.push({ categoryId: 0, content: Tmp });
            Tmp = [];
          }
        });

        if (Tmp.length > 0)
          Data.push({ categoryId: 0, content: Tmp });

        return Data;
      },
      MeunContentData() {
        var data = [];
        this.MobileMeun.forEach(function(item) {
          if (item.id == '6')
            data.push({ categoryId: item.id, id: item.id, Maintain: '', gameName: item.title });
          else
            item.tabs.forEach(function(link) {
              data.push({ categoryId: item.id, id: link.Id, maintain: link.Maintain, gameName: link.GameName });
            });
        });
        return data;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'IsLeft', 'Jackpot', 'IsMobile']),
      ...mapGetters(["MobileMeun", "Product", 'HotMeun', 'HotGame']),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    },
  };
</script>
<style>
.gameli{
  margin-top: 10px;
}
</style>
